import React, { useState } from 'react';
import moment from 'moment';
import '../../css/main.css';
import '../../css/occupancy.css'; 

class Table extends React.Component {
    constructor(props) {
      super(props);
      this.columns = 3; 
      this.timeElapsed = moment(); 
      this.tick = this.tick.bind(this); 

      this.state = { remainingTime: moment.duration(this.props.expirys[0].diff(moment())) };
      var rts = []
      for (var i=0 ; i<this.props.numSpots; i++){
        rts.push(moment.duration(this.props.expirys[i].diff(moment())))        
      }
      this.state.remainingTimes = rts ;
    };

    static getDerivedStateFromProps(props, state) {
        const rt = [];
        for (var i=0 ; i<props.numSpots; i++){
          rt.push( moment.duration(props.expirys[i].diff(moment())) ) 
        }
        return { remainingTimes: rt };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
      const rt = [];
      for (var i=0 ; i<this.props.numSpots; i++){
        if (this.state.remainingTimes[i] > 0 ) {
          rt.push( moment.duration(this.props.expirys[i].diff(moment())) ) 
        }else{
          rt.push( moment.duration( 0 ) ) 
        }
      }
      this.setState({
          remainingTimes: rt
      });
    }

    getHourString(i){
      if (this.state.remainingTimes[i].get('hours') < 10){
          return "0"+this.state.remainingTimes[i].get('hours');
      }
      return this.state.remainingTimes[i].get('hours');
    }

    getMinuteString(i){
        if (this.state.remainingTimes[i].get('minutes') < 10){
            return "0"+this.state.remainingTimes[i].get('minutes');
        }
        return this.state.remainingTimes[i].get('minutes');
    }

    getSecondString(i){
        if (this.state.remainingTimes[i].get('seconds') < 10){
            return "0"+this.state.remainingTimes[i].get('seconds');
        }
        return this.state.remainingTimes[i].get('seconds');
    }
  
    getTimeRemainingString(i){
      if (this.isExpired(i)){
        return "EXPIRED"
      } else{
        return this.getHourString(i).concat(" hr ", this.getMinuteString(i), " min ", this.getSecondString(i), " s")
      }
    }

    getExpiryString(i){
        return this.props.expirys[i].format("hh:mm A")
    }

    getDayDiffString(i){
      const daysAhead = this.state.remainingTimes[i].get('days')
      if (daysAhead<0){
        return "(".concat(daysAhead,")")
      } else{ 
        return ""
      }
    }

    isExpired(i){
      if (this.state.remainingTimes[i] < 0){
        return true; 
      } else{
        return false; 
      }
    }
  
    render() {
      let rows = [] 
      for (var i = 0; i < this.props.numSpots; i++){
        let rowID = `row${i}`
        let cell = []

        for (var idx = 0; idx < this.columns; idx++){
          let cellID = `cell${i}-${idx}`
          if (idx === 0){
            cell.push(<td key={cellID} id={cellID}> {this.props.names[i]} </td>);
          }
          if (idx === 1){
              cell.push(<td key={cellID} id={cellID} > { this.getTimeRemainingString(i) } </td>)
          }
          if (idx === 2){
            cell.push(<td key={cellID} id={cellID}> {this.getExpiryString(i)} {this.getDayDiffString(i)} </td>);
          }
        }
        if (i%2 === 1){
          if (this.isExpired(i)){
            rows.push(<tr className="odd-exp" key={i} id={rowID}>{cell}</tr>)
          }else{
            rows.push(<tr className="odd" key={i} id={rowID}>{cell}</tr>)
          }
        }else{
          if (this.isExpired(i)){
            rows.push(<tr className="even-exp" key={i} id={rowID}>{cell}</tr>)
          }else{
            rows.push(<tr className="even" key={i} id={rowID}>{cell}</tr>)
          }        }
      }

      return (
        <section className="view-spots">
          <div className="title">  
            You are Viewing: 
            <span className="highlight"> {this.props.lotName}</span>
          </div>
          <div className="container">
            <table className="table" >
              <thead >
                <tr>
                  <th className="head"> Spot Name</th>
                  <th className="head">Time Remaining</th>
                  <th className="head">Expire Time</th>
                </tr>
              </thead>
              <tbody className="body">
                {rows}
              </tbody>
            </table>
          </div>
          <p className="invisible_dot"> . </p>
        </section>
  
      )
    }
  }

export {Table};